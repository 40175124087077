export const NavbarLinks = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "Search Test",
      path: "/allTest",
    },
  
  
  
  
    {
      title: "Visit Lab",
      path: "/contact",
    },


    
  
  ];
  